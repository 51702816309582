import axios from "axios";

const BASE_URL = "https://whereisdumpster-oops.pro/api/dumpster";
const secure_base_url = "https://whereisdumpster-oops.pro/api/dumpster";
const headers = {
  "Content-Type": "application/json",
};
axios.defaults.withCredentials = false;

export function CreateDumpster(formData) {
  console.log('si carga')
  return axios.post(secure_base_url, formData, { headers });
}
