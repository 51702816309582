import React, { useState } from 'react';
import Formulario from './Formulario'
import { Box } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme();
const Page = () => {
return(
    <body>
    <header>
        <div className="container">
            <h1>Registro de punto de recolección</h1>
        </div>
    </header>
    <main
        style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        }}
    >
        <ThemeProvider theme={theme}>
        <Box 
            sx={{
                minWidth: {xs: '370px', sm: '370px', md: '800px'}
            }}
        >
            <article>
                <Formulario />
            </article>
        </Box>
        </ThemeProvider>
    </main>
    <footer>
        <div className="container">
            <p>Derechos de autor &copy; 2024</p>
        </div>
    </footer>
</body>

);
};

export default Page;