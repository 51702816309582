// src/Formulario.js
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { Checkbox, Grid, Box } from '@mui/material';
import { CreateDumpster } from "../src/apis/DumpsterApi";
import { countries } from './countries/countries'

import { MapContainer, TileLayer, Marker, Popup, useMapEvents,
    MapConsumer } from 'react-leaflet';
import L from 'leaflet';
import "leaflet/dist/leaflet.css";

import iconoMarcador from 'leaflet/dist/images/marker-icon.png';
import iconoMarcadorRetina from 'leaflet/dist/images/marker-icon-2x.png';
import iconoMarcadorSombra from 'leaflet/dist/images/marker-shadow.png';
//import L from "leaflet";
//import icon from "./constants";
let DefaultIcon = L.icon({
    iconUrl: iconoMarcador,
    iconRetinaUrl: iconoMarcadorRetina,
    shadowUrl: iconoMarcadorSombra,
    iconAnchor: [12, 41],
    iconSize: [25, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
  });

L.Marker.prototype.options.icon = DefaultIcon;

const Formulario = () => {
 

  const [responseMessage, setResponseMessage] = useState("");

  const [direccion, setDireccion] = useState([51.505, -0.09]);
  const [formData, setFormData] = useState({
    

    address: [""],
    anonymous: false,
    resident: false,
    country: "",
    bintypetrash: "",
    binCondition: false,
    comments: "",
    binsize: "",
    email: "",
  });


  const handleFormSubmit = (e) => {
      console.log("entro submit" , formData)
      CreateDumpster(formData)
        .then((response) => {
          console.log(response)
          if (!response.result) {
            setResponseMessage(
              "Ocurrió un error en el inicio de sesión, inténtelo mas tarde."
            );
            return;
          }else{
            setResponseMessage(
              "Información guardada exitosamente."
            );
            return;
          }
        
        })
        .catch((error) => {
          if (error.message) {
            setResponseMessage(
              "Ocurrió un error en el inicio de sesión, inténtelo mas tarde."
            );
            return;
          } else {
            setResponseMessage(
              "Ocurrió un error en el inicio de sesión, inténtelo mas tarde."
            );
          }
        });
      e.preventDefault();
    
  };

  const handleMapClick = (e) => {
    setDireccion([e.latlng.lat, e.latlng.lng]);
    setFormData({
      ...formData,
      address: [e.latlng.lat, e.latlng.lng],
    });
    console.log('handleMapClick: ', formData)
  };

  const hanldeCountry = (event) => {
    setFormData({
      ...formData,
      country: event.target.value,
    });
    console.log('entro al select', event, formData.country)
    
  };

  const handleBinTypeTrash = (event) => {
    setFormData({
      ...formData,
      bintypetrash: event.target.value,
    });
    console.log('entro al select', event, formData.bintypetrash)
    
  };


  const handleBinSize = (event) => {
    setFormData({
      ...formData,
      binsize: event.target.value,
    });
  };



  const Markers = () => {
    console.log("entrop a markers")

    const map = useMapEvents({
      
        click(e) {                                
            setDireccion([
                e.latlng.lat,
                e.latlng.lng
            ]);
            setFormData({
              ...formData,
              address: [e.latlng.lat, e.latlng.lng],
            });
        },            
    })

    return (
        direccion ? 
            <Marker           
            key={direccion[0]}
            position={direccion}
            interactive={false} 
            >
            <Popup>Seleccionaste esta ubicación</Popup>
            </Marker>
        : null
    )   
    
}

  return (
    <Grid container style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      <br/>
      <form onSubmit={handleFormSubmit} style={{ maxWidth: '600px'}}>
        <Grid container>

        <Grid item xs={12} sm={12} md={12}
          sx={{
            maxWidth: {xs: '300px', sm: '300px', md: '600px'}
          }}
        >
          <InputLabel htmlFor="direccion" style={{ textAlign: "left", fontWeight: "bold", color: "black",  marginBottom: '10px'}}>Ubicación del contenedor de basura</InputLabel>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginBottom: '16px' }}>
            <Box
              
            sx ={{
              maxWidth: "500px"
              
            }}
            
            >
            <MapContainer center={[51.505, -0.09]} zoom={13} onClick={handleMapClick} scrollWheelZoom={false}>
                <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Markers />
            </MapContainer>
            </Box>
          </div>
        
        

          
          <h3 style={{marginBottom: "15px"}}>Ayúdanos con información</h3>

          <InputLabel id="contribucionId" style={ {textAlign: "left", fontWeight: "bold", color: "black", marginBottom: "10px"}}>Tipo de contribución</InputLabel>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: "16px"}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
              <Checkbox
                  checked={formData.anonymous}
                  onChange={(e) => setFormData({
                    ...formData,
                    anonymous: e.target.checked,
                  })}
                  label="Anónimo"
                  labelId="anonymousId"
                />
                <InputLabel id="anonymousId" >Anónimo</InputLabel>
            </div>
            
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
              <Checkbox
                checked={!formData.anonymous}
                onChange={(e) => setFormData({
                  ...formData,
                  anonymous: !e.target.checked,
                })}
                label="No anónimo"
                labelId="noAnonymousId"
              />
              <InputLabel id="noAnonymousId" >No anónimo</InputLabel>
            </div>
          </div>


          <div style={{ display: formData.anonymous ?  'none' : 'block' }}>
            <InputLabel id="email" style={ {textAlign: "left", fontWeight: "bold", color: "black", marginBottom: "10px"}}>Correo electrónico</InputLabel>
            <TextField
              label="Correo electrónico"
              variant="outlined"
              color="primary"
              fullWidth
              style={{ marginBottom: '16px' }}
              value={formData.email}
              onChange={(e) => setFormData({
                ...formData,
                email: e.target.value,
              })
            }
            />
          </div>



          <InputLabel id="contribucionId" style={ {textAlign: "left", fontWeight: "bold", color: "black", marginBottom: "10px"}}>Es usted residente o turista en Japón</InputLabel>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: "16px"}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
              <Checkbox
                  checked={formData.resident}
                  onChange={(e) => setFormData({
                    ...formData,
                    resident: e.target.checked,
                  })}
                  label="Residente"
                />
                <InputLabel id="residenteId" >Residente</InputLabel>
            </div>
            
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
              <Checkbox
                checked={!formData.resident}
                onChange={(e) => setFormData({
                  ...formData,
                  resident: !e.target.checked,
                })}
                label="Turista"
              />
              <InputLabel id="turistaId" >Turista</InputLabel>
            </div>
          </div>


          
          <InputLabel id="country" style={ {textAlign: "left", fontWeight: "bold", color: "black", marginBottom: "10px"}}>País de residencia</InputLabel>
          <Select
              labelId="country"
              variant="outlined"
              color="primary"
              fullWidth
              style={{ marginBottom: '16px' }}
              value={formData.country}
              onChange={hanldeCountry}  
            >
              {countries.map((country) => (
                <MenuItem key={country} value={country}>
                  {country}
                </MenuItem>
              ))}
            </Select>

          <InputLabel id="bintype-trash" style={ {textAlign: "left", fontWeight: "bold", color: "black", marginBottom: "10px"}}>Tipo de basura</InputLabel>
          <Select
            labelId="bintype-trash"
            variant="outlined"
            color="primary"
            fullWidth
            style={{ marginBottom: '16px' }}
            value={formData.bintypetrash}
            onChange={handleBinTypeTrash}  
          >
            <MenuItem value="general">Común</MenuItem>
            <MenuItem value="other">Otro</MenuItem>
          </Select>

          <InputLabel id="binsize-label" style={ {textAlign: "left", fontWeight: "bold", color: "black", marginBottom: "10px"}}>Tamaño del contenedor</InputLabel>
          <Select
            labelId="binsize-label"
            variant="outlined"
            color="primary"
            fullWidth
            style={{ marginBottom: '16px' }}
            value={formData.binsize}
            onChange={handleBinSize}  
          >
            <MenuItem value="small">Pequeño</MenuItem>
            <MenuItem value="medium">Mediano</MenuItem>
            <MenuItem value="large">Grande</MenuItem>
            <MenuItem value="extra-large">Extra grande</MenuItem>

          </Select>


          <InputLabel id="binState" style={ {textAlign: "left", fontWeight: "bold", color: "black", marginBottom: "10px"}}>Reporta el estado del contenedor</InputLabel>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: "16px"}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
              <Checkbox
                  checked={formData.binCondition}
                  onChange={(e) => setFormData({
                    ...formData,
                    binCondition: e.target.checked,
                  })}
                  label="Dañado"
                />
                <InputLabel id="damagedId" >Dañado</InputLabel>
            </div>
            
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
              <Checkbox
                checked={!formData.binCondition}
                onChange={(e) => setFormData({
                  ...formData,
                  binCondition: !e.target.checked,
                })}
                label="Buena condición"
              />
              <InputLabel id="goodConditionId" >Buena condición</InputLabel>
            </div>
          </div>

          <InputLabel id="comments" style={ {textAlign: "left", fontWeight: "bold", color: "black", marginBottom: "10px"}}>Escribe un comentario</InputLabel>
          <TextField
            label="Comentario máximo (150 caracteres)"
            variant="outlined"
            color="primary"
            fullWidth
            multiline
            inputProps={{ maxLength: 150 }}
            rows={4}
            style={{ marginBottom: '16px', height: "100px"}}
            value={formData.comments}
            onChange={(e) => setFormData({
              ...formData,
              comments: e.target.value,
            })
          }
          />

        <br/> 
        <br/>
        <Button type="submit" variant="contained" color="primary">Enviar información</Button>

        </Grid>
        </Grid>
        
      </form>
    </Grid>
  );
};

export default Formulario;